<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '60vw' }"
  styleClass="mx-3 text-center"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
  (onHide)="onHide($event)"
>
  <ng-template pTemplate="header">
    <div
      class="d-flex justify-content-center w-100"
      style="margin-right: -2rem"
    >
      <h4 class="fw-bold mb-0">FUN HUB Practice Resources</h4>
    </div>
  </ng-template>

  <p class="mb-4">Select a level to view resources.</p>

  <div class="row mx-5">
    <div class="col-4">
      <img
        role="button"
        src="/assets/img/fhp/fhp-resources-1.svg"
        alt="Level 1 resources"
        class="mw-100"
        (click)="openResource(1)"
      />
    </div>
    <div class="col-4">
      <img
        role="button"
        src="/assets/img/fhp/fhp-resources-2.svg"
        alt="Level 2 resources"
        class="mw-100"
        (click)="openResource(2)"
      />
    </div>
    <div class="col-4">
      <img
        role="button"
        src="/assets/img/fhp/fhp-resources-3.svg"
        alt="Level 3 resources"
        class="mw-100"
        (click)="openResource(3)"
      />
    </div>
  </div>
</p-dialog>
