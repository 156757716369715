<p-dropdown
  *ngIf="hasMultipleTenants"
  appendTo="body"
  placeholder="{{ tenantName }}"
  styleClass="w-100"
  [options]="availableTenants"
  [(ngModel)]="selectedTenant"
  (onChange)="onSelectedTenantChange()"
  optionLabel="name"
></p-dropdown>
