import * as i0 from '@angular/core';
import { Component, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/router';
function NotFoundComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "img", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("src", ctx_r0.iconUrl, i0.ɵɵsanitizeUrl);
  }
}
function NotFoundComponent_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12)(1, "p");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.notFoundData.message);
  }
}
function NotFoundComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 13);
    i0.ɵɵlistener("click", function NotFoundComponent_div_10_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.goBack());
    });
    i0.ɵɵtext(2, " Go Back ");
    i0.ɵɵelementEnd()();
  }
}
class NotFoundComponent {
  constructor(route) {
    this.route = route;
  }
  ngOnInit() {
    this.initialize();
    this.showBackButton();
  }
  initialize() {
    this.notFoundData = this.route.snapshot.data['notFoundData'];
    this.goBackFn = this.notFoundData?.goBackFn;
    document.title = this.notFoundData?.title || 'Page Not Found';
  }
  showBackButton() {
    if (this.goBackFn) {
      this.isBackButtonVisible = true;
    }
  }
  goBack() {
    if (this.goBackFn) {
      this.goBackFn();
    }
  }
  static {
    this.ɵfac = function NotFoundComponent_Factory(t) {
      return new (t || NotFoundComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NotFoundComponent,
      selectors: [["wilson-not-found"]],
      decls: 17,
      vars: 5,
      consts: [["id", "message-page", 1, "wlt-message-page"], ["class", "floating-icons", 4, "ngIf"], [1, "msg-container"], [1, "title", "error"], [1, "error-code"], [1, "error-text"], ["class", "alert alert-danger", 4, "ngIf"], [4, "ngIf"], [1, "contact-info"], ["href", "https://www.wilsonlanguage.com/contact-us", "target", "_blank"], [1, "floating-icons"], [3, "src"], [1, "alert", "alert-danger"], ["id", "goBack", 1, "btn", "btn-secondary", 3, "click"]],
      template: function NotFoundComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, NotFoundComponent_div_1_Template, 2, 1, "div", 1);
          i0.ɵɵelementStart(2, "div", 2)(3, "h1", 3)(4, "span", 4);
          i0.ɵɵtext(5, "404");
          i0.ɵɵelementEnd();
          i0.ɵɵelement(6, "br");
          i0.ɵɵelementStart(7, "span", 5);
          i0.ɵɵtext(8, "Page Not Found");
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(9, NotFoundComponent_div_9_Template, 3, 1, "div", 6)(10, NotFoundComponent_div_10_Template, 3, 0, "div", 7);
          i0.ɵɵelementStart(11, "div", 8)(12, "p");
          i0.ɵɵtext(13, " If you believe you are seeing this page in error, please ");
          i0.ɵɵelementStart(14, "a", 9);
          i0.ɵɵtext(15, "contact Customer Support");
          i0.ɵɵelementEnd();
          i0.ɵɵtext(16, ". ");
          i0.ɵɵelementEnd()()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.notFoundData == null ? null : ctx.notFoundData.iconUrl);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("hasIcons", ctx.notFoundData == null ? null : ctx.notFoundData.iconUrl);
          i0.ɵɵadvance(7);
          i0.ɵɵproperty("ngIf", ctx.notFoundData == null ? null : ctx.notFoundData.message);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.isBackButtonVisible);
        }
      },
      dependencies: [i2.NgIf],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotFoundComponent, [{
    type: Component,
    args: [{
      selector: 'wilson-not-found',
      template: "<div id=\"message-page\" class=\"wlt-message-page\">\r\n  <div class=\"floating-icons\" *ngIf=\"notFoundData?.iconUrl\">\r\n    <img [src]=\"iconUrl\" />\r\n  </div>\r\n  <div class=\"msg-container\" [class.hasIcons]=\"notFoundData?.iconUrl\">\r\n    <h1 class=\"title error\">\r\n      <span class=\"error-code\">404</span>\r\n      <br />\r\n      <span class=\"error-text\">Page Not Found</span>\r\n    </h1>\r\n    <div class=\"alert alert-danger\" *ngIf=\"notFoundData?.message\">\r\n      <p>{{ notFoundData.message }}</p>\r\n    </div>\r\n    <div *ngIf=\"isBackButtonVisible\">\r\n      <button id=\"goBack\" class=\"btn btn-secondary\" (click)=\"goBack()\">\r\n        Go Back\r\n      </button>\r\n    </div>\r\n    <div class=\"contact-info\">\r\n      <p>\r\n        If you believe you are seeing this page in error, please\r\n        <a href=\"https://www.wilsonlanguage.com/contact-us\" target=\"_blank\"\r\n          >contact Customer Support</a\r\n        >.\r\n      </p>\r\n    </div>\r\n  </div>\r\n</div>\r\n"
    }]
  }], () => [{
    type: i1.ActivatedRoute
  }], null);
})();
class NotFoundModule {
  static {
    this.ɵfac = function NotFoundModule_Factory(t) {
      return new (t || NotFoundModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NotFoundModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotFoundModule, [{
    type: NgModule,
    args: [{
      declarations: [NotFoundComponent],
      imports: [CommonModule],
      exports: [NotFoundComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NotFoundComponent, NotFoundModule };
