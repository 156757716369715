import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DistrictReportsService } from 'src/app/services/district-reports.service';
import mixpanel from 'mixpanel-browser';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { Wilson } from 'src/def/Wilson';
import GetDistrictReportsResponse = Wilson.GetDistrictReportsResponse;

@Component({
  templateUrl: './district-reports.component.html',
})
export class DistrictReportsComponent implements OnInit {
  districtReport: GetDistrictReportsResponse;

  benchmarkByUnitOptions = {
    animation: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
      },
    },
  };

  districtAverageOptions = {
    animation: false,
    scales: {
      y: {
        stacked: true,
        min: 0,
        max: 100,
      },
      x: {
        stacked: true,
      },
    },
  };

  constructor(
    private districtReportsService: DistrictReportsService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.getDistrictReports().subscribe();
    mixpanel.track('Go To District Reports');
  }

  getDistrictReports(): Observable<GetDistrictReportsResponse> {
    return this.districtReportsService.getDistrictReports().pipe(
      tap((districtReport: GetDistrictReportsResponse) => {
        this.districtReport = districtReport;
        mixpanel.track('District Report Loaded', {
          district: districtReport.name,
        });
      })
    );
  }

  trackLevelChange(index: number) {
    this.mixpanelService.trackLevelChange(index);
  }

  getTracking(index, metric: GetDistrictReportsResponse): string {
    return metric.name;
  }
}
