import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from '../../def/Wilson';
import GetCompletedByStudentResponse = Wilson.GetCompletedByStudentResponse;
import RequestCompletedByStudent = Wilson.RequestCompletedByStudent;
import GetCompletedByClassResponse = Wilson.GetCompletedByClassResponse;
import RequestCompletedByClass = Wilson.RequestCompletedByClass;
import GetActivitiesTotalsResponse = Wilson.GetActivitiesTotalsResponse;
import RequestCompletedBySchool = Wilson.RequestCompletedBySchool;

@Injectable({
  providedIn: 'root',
})
export class FunAdminDashboardService {
  constructor(private apiService: ApiService) {}

  getCompletedByStudent(
    classId: string,
    unit: number,
    weeks: number[]
  ): Observable<GetCompletedByStudentResponse[]> {
    return this.apiService.post<GetCompletedByStudentResponse[]>(
      `admin-dashboard/completed-by-student`,
      {
        classId: classId,
        unit: unit,
        weeks: weeks,
      } as RequestCompletedByStudent
    );
  }

  getCompletedByClass(
    unit: number,
    level: number,
    startingYear: number,
    schoolId: string
  ): Observable<GetCompletedByClassResponse[]> {
    return this.apiService.post<GetCompletedByClassResponse[]>(
      `admin-dashboard/completed-by-class`,
      {
        unit: unit,
        level: level,
        startingYear: startingYear,
        schoolId: schoolId,
      } as RequestCompletedBySchool
    );
  }

  getActivitiesTotalsForClass(
    unit: number,
    level: number,
    classId: string
  ): Observable<GetActivitiesTotalsResponse[]> {
    return this.apiService.post<GetActivitiesTotalsResponse[]>(
      `admin-dashboard/activities-class-totals`,
      {
        unit: unit,
        level: level,
        classId: classId,
      } as RequestCompletedByClass
    );
  }

  getActivitiesTotalsForSchool(
    unit: number,
    level: number,
    startingYear: number,
    schoolId: string
  ): Observable<GetActivitiesTotalsResponse[]> {
    return this.apiService.post<GetActivitiesTotalsResponse[]>(
      `admin-dashboard/activities-school-totals`,
      {
        unit: unit,
        level: level,
        startingYear: startingYear,
        schoolId: schoolId,
      } as RequestCompletedBySchool
    );
  }
}
