import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class AuthCheckInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Don't reset the timer on these endpoints
    const whitelist = ['user/authed', 'user/login'];
    if (!whitelist.some((url) => req.url.includes(url))) {
      this.userService.setAuthCheckTimer();
    }
    return next.handle(req.clone());
  }
}
