<h1>My Dashboard</h1>

<div class="report-filter">
  <app-fun-state
    [isWeekMultiSelect]="true"
    [showOwnedOnly]="true"
    (stateChange)="getReports($event)"
  ></app-fun-state>

  <p-button
    *ngIf="!!state?.class"
    [routerLink]="[
      '/fhp/assign',
      { classId: state?.class.id, unit: state?.unit }
    ]"
    label="Create Assignment"
    class="ms-auto"
    trackClick="FHPCreateAssignmentDB"
  ></p-button>
</div>

<ng-container *ngIf="studentReport">
  <div class="report-header mb-4">
    <h5 class="fw-bold m-0">
      FHP Performance on Completed Activities by Student
    </h5>
    <small class="text-secondary fst-italic">
      Click a section on the pie chart to view
    </small>
  </div>

  <div class="report">
    <div class="report-row">
      <div class="card" *ngFor="let weekReport of studentReport; let i = index">
        <h4 class="fw-bold text-center mb-4">{{ weekReport.title }}</h4>
        <p-chart
          title="Click to see data"
          [data]="weekReport"
          [options]="chartOptions[i]"
          [plugins]="chartPlugins"
          (onDataSelect)="selectChartArea($event, i)"
          type="doughnut"
          class="clickable-chart"
          alt="A visual representation of FHP performance on completed activities by student by week."
        ></p-chart>
        <small
          class="fw-bold mt-4 mb-2 mx-3"
          [ngSwitch]="selectedChartAreas[i]"
        >
          <span *ngSwitchCase="0">Students in green</span>
          <span *ngSwitchCase="1">Students in yellow</span>
          <span *ngSwitchCase="2">Students in red</span>
          <span *ngSwitchCase="4" class="text-secondary fw-normal">
            Select a section on the chart to view student results.
          </span>
          <span *ngSwitchDefault class="text-secondary fw-normal">
            No Students
          </span>
        </small>
        <ul
          class="student-list list-unstyled mx-3 mb-0"
          [ngSwitch]="selectedChartAreas[i]"
        >
          <ng-container *ngSwitchCase="0">
            <div
              *ngFor="let student of weekReport.studentsAboveBench | keyvalue"
            >
              <button
                type="button"
                class="btn btn-link p-1"
                style="text-decoration: none"
                [routerLink]="['/fhp/students/report', student.value]"
              >
                {{ student.key }}
              </button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <div *ngFor="let student of weekReport.studentsAtRisk | keyvalue">
              <button
                type="button"
                class="btn btn-link p-1"
                style="text-decoration: none"
                [routerLink]="['/fhp/students/report', student.value]"
              >
                {{ student.key }}
              </button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div
              *ngFor="let student of weekReport.studentsBelowBench | keyvalue"
            >
              <button
                type="button"
                class="btn btn-link p-1"
                style="text-decoration: none"
                [routerLink]="['/fhp/students/report', student.value]"
              >
                {{ student.key }}
              </button>
            </div>
          </ng-container>
        </ul>
      </div>
    </div>

    <ng-container *ngIf="studentReport.length == 0">
      <div class="empty-message">
        <p>There is no data available for this selection right now.</p>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="activityReport">
  <div class="report-header my-4">
    <h5 class="fw-bold m-0">
      FHP Performance on Completed Activities by Activity
    </h5>
  </div>

  <div class="report">
    <div class="report-row">
      <div class="card" *ngFor="let weekReport of activityReport">
        <h4 class="fw-bold text-center mb-2">Week {{ weekReport.week }}</h4>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col">Complete</th>
              <th scope="col">Activity</th>
              <th scope="col" class="text-center" style="width: 5rem">
                Class Result
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let activity of weekReport.resultsByActivities">
              <td>
                {{ activity.totalCompleted }}/{{ activity.totalAssigned }}
              </td>
              <td>{{ activity.activityName }}</td>
              <td class="text-center">
                <div
                  *ngIf="activity.totalCompleted > 0"
                  class="score-marker"
                  [class.danger]="
                    activity.classAverage <= benchmarkScores.failing.max
                  "
                  [class.warning]="
                    activity.classAverage >= benchmarkScores.atRisk.min &&
                    activity.classAverage <= benchmarkScores.atRisk.max
                  "
                  [class.success]="
                    activity.classAverage >= benchmarkScores.onTrack.min
                  "
                >
                  {{ activity.classAverage }}
                </div>
                <div *ngIf="activity.totalCompleted == 0" class="score-marker">
                  N
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p-button
          class="mt-2 mx-auto"
          label="Student Results"
          icon="pi pi-chevron-right"
          iconPos="right"
          [routerLink]="[
            '/fhp/students',
            {
              classId: this.state.class.id,
              unit: this.state.unit,
              week: weekReport.week
            }
          ]"
          trackClick="FHPStudentResultsDB"
        ></p-button>
      </div>
    </div>

    <ng-container *ngIf="activityReport.length == 0">
      <div class="empty-message">
        <p>There is no data available for this selection right now.</p>
      </div>
    </ng-container>
  </div>
</ng-container>

<app-empty-roster *ngIf="state?.isEmpty"></app-empty-roster>
