<div id="app">
  <router-outlet></router-outlet>

  <!-- custom toast notifications -->
  <p-toast position="bottom-center">
    <ng-template let-message pTemplate="message">
      <div class="w-100">
        <h5 *ngIf="message.detail">{{ message.summary }}</h5>
        <p [innerHTML]="message.detail || message.summary" class="m-0"></p>
      </div>
    </ng-template>
  </p-toast>

  <!-- custom confirmation dialog -->
  <p-confirmDialog
    acceptButtonStyleClass="p-button-primary"
    rejectButtonStyleClass="p-button-text p-button-secondary"
  ></p-confirmDialog>
</div>

<wilson-spinner></wilson-spinner>

<div *ngIf="loadingMessage" class="loadingMessage">
  {{ loadingMessage }}
</div>
