import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { InitialLoadService } from '../../services/initial-load.service';
import { Wilson } from 'src/def/Wilson';
import FunMenuSection = Wilson.FunMenuSection;

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: AppMenuSection[] = [];
  links: AppMenuSection;

  resourcesVisible: boolean = false;

  constructor(
    private menuService: MenuService,
    private initialLoadService: InitialLoadService
  ) {}

  ngOnInit() {
    this.loadMenuItems().subscribe();
    this.loadOtherLinksItem();
  }

  loadOtherLinksItem(): void {
    this.links = {
      label: 'Other Links',
      icon: 'open-in-new',
      items: [
        {
          label: 'FUN HUB Practice Resources',
          trackingEventName: 'FHPResources',
          command: () => (this.resourcesVisible = true),
        },
        {
          label: 'Return to FUN HUB®',
          trackingEventName: 'FHReturn',
          command: () =>
            window.open(this.initialLoadService.initialLoad.funHubUrl),
        },
      ],
    };
  }

  private loadMenuItems(): Observable<FunMenuSection[]> {
    return this.menuService.getMenuItems().pipe(
      tap((sections: FunMenuSection[]) => {
        this.model = sections.map((section) => ({
          label: section.label,
          icon: section.icon,
          routerLink: section.url == '' ? null : section.url,
          items: section.items.map((item) => {
            return {
              label: item.label,
              routerLink: item.url,
              trackingEventName: item.trackingEventName,
            } as AppMenuItem;
          }),
        }));
      })
    );
  }
}

interface AppMenuSection extends AppMenuItem {
  icon: string;
  items: AppMenuItem[];
}

interface AppMenuItem {
  label: string;
  routerLink?: string;
  command?: Function;
  trackingEventName?: string;
}
