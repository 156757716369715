import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import FunMenuSection = Wilson.FunMenuSection;

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  controller = 'menu';

  constructor(private apiService: ApiService) {}

  getMenuItems(): Observable<FunMenuSection[]> {
    return this.apiService.get<FunMenuSection[]>(
      `${this.controller}/GetMenuItems/`
    );
  }
}
