<div class="d-flex flex-column h-100" *ngIf="isMenuVisible">
  <a
    class="layout-topbar-logo pt-3 pb-5"
    routerLink=""
    aria-label="Navigate Home"
  >
    <img
      alt="Fundations logo"
      src="./assets/img/fundations-full{{
        theme !== 'light' ? '-white' : '-black'
      }}.svg"
    />
  </a>

  <app-menu class="flex-grow-1"></app-menu>
</div>
