import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formattedDate' })
export class DateTransformPipe implements PipeTransform {
  transform(date: Date): string {
    const today = new Date();
    const dayDiff = Math.floor(
      (today.getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24)
    );

    if (dayDiff == 0) {
      return 'Today';
    } else if (dayDiff <= 7) {
      return `${dayDiff} day${dayDiff == 1 ? '' : 's'} ago`;
    } else if (dayDiff < 100) {
      return new Date(date).toDateString();
    } else return '-';
  }
}
