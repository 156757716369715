import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InitialLoadService } from '../../services/initial-load.service';

@Component({
  selector: 'app-fhp-resources',
  templateUrl: './fhp-resources.component.html',
})
export class FhpResourcesComponent implements OnInit {
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  private resources: { [p: number]: string };

  constructor(private initialLoadService: InitialLoadService) {}

  ngOnInit(): void {
    this.resources = this.initialLoadService.initialLoad.funHubResources;
  }

  onHide(_: any) {
    this.visibleChange.emit(false);
  }

  openResource(level: number) {
    window.open(this.resources[level]);
    this.visibleChange.emit(false);
  }
}
