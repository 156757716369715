import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '@wilson/wilsonng';
@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  //add any URLs that should be exempt from the tenant global query filter to this array
  private readonly exemptUrls = [
    'initial-load',
    'user/login-user',
    'user/authed',
    'menu/GetMenuItems/',
  ];
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.exemptUrls.some((url) => req.url.includes(url))) {
      return next.handle(req.clone());
    }
    const selectedTenant = localStorage.getItem(UserService.tenantStorageKey);
    if (!selectedTenant) {
      this.authService.logout();
      return null;
    }
    const reqWithCustomHeader = req.clone({
      headers: req.headers.set('utt-tenant', selectedTenant),
    });
    return next.handle(reqWithCustomHeader);
  }
}
