import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import UserTenant = Wilson.UserTenant;

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private apiService: ApiService) {}

  updateTenant(userTenant: UserTenant): Observable<void> {
    return this.apiService.post<void>(`tenant/selected-tenant`, userTenant);
  }
}
