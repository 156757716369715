import * as i0 from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import * as i1 from '@angular/platform-browser';
class TitleService {
  constructor(titleControl, productName) {
    this.titleControl = titleControl;
    this.productName = productName;
    this.pageTitle = '';
    this.qualifier = '';
    this.productOverride = null;
    this.updateTitle();
  }
  /**
   * override the productName provided in AppModule
   * @param text value to display as the base
   */
  setProductOverride(text) {
    if (text !== null) {
      this.productOverride = text || '';
    } else {
      this.productOverride = this.productName;
    }
    this.updateTitle();
  }
  /**
   * sets the title's qualifier
   * @param text value to display after the base
   */
  setQualifier(text) {
    this.qualifier = text || '';
    this.updateTitle();
  }
  /**
   * sets the page name in the title
   * @param text value to display before the base
   */
  setPageName(text) {
    this.pageTitle = text || '';
    this.updateTitle();
  }
  /**
   * Generates a new title string with the given qualifier
   * @param text value to display after the base
   * @returns newly generated title
   */
  generatePageTitleWithNewQualifier(text) {
    const qualifier = text || '';
    return this.generateTitle(this.pageTitle, qualifier);
  }
  /**
   * Generates a new title string with the given page name
   * @param text value to display before the base
   * @returns
   */
  generatePageTitleWithNewName(text) {
    const name = text || '';
    return this.generateTitle(name, this.qualifier);
  }
  /**
   * combines the different title parts into the
   * final value to be shown, and updates the page's title
   */
  updateTitle() {
    const title = this.generateTitle(this.pageTitle, this.qualifier);
    this.titleControl.setTitle(title);
  }
  generateTitle(pageTitle, qualifier) {
    let title = pageTitle;
    if (pageTitle) {
      title += ' - ';
    }
    if (this.productOverride || this.productOverride === '') {
      title += this.productOverride;
    } else {
      title += this.productName;
    }
    if (qualifier) {
      title += ' ' + qualifier;
    }
    return title;
  }
  static {
    this.ɵfac = function TitleService_Factory(t) {
      return new (t || TitleService)(i0.ɵɵinject(i1.Title), i0.ɵɵinject('productName'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TitleService,
      factory: TitleService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TitleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Title
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['productName']
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TitleService };
