<ul class="layout-menu" role="navigation">
  <ng-container *ngFor="let item of model; let i = index">
    <li [index]="i" [item]="item" [root]="true" class="mb-2" app-menuitem></li>
  </ng-container>

  <div class="mt-auto">
    <li
      [index]="model.length"
      [item]="links"
      [root]="true"
      class="mb-2"
      app-menuitem
    ></li>
  </div>
</ul>

<app-fhp-resources [(visible)]="resourcesVisible"></app-fhp-resources>
