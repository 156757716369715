import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import mixpanel from 'mixpanel-browser';

export interface AppConfig {
  theme: string;
  menuMode: string;
}

export interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  profileSidebarVisible: boolean;
  configSidebarVisible: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
}

export interface MenuChangeEvent {
  key: string;
  routeEvent?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  config: AppConfig = {
    menuMode: 'static',
    theme: localStorage.getItem('theme') ?? 'light',
  };

  state: LayoutState = {
    staticMenuDesktopInactive: localStorage.getItem('menuState') === 'true',
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
  };
  private isMenuActive = new BehaviorSubject<boolean>(
    this.state.staticMenuDesktopInactive
  );
  isMenuPrinted$ = this.isMenuActive.asObservable();
  private configUpdate = new Subject<AppConfig>();
  configUpdate$ = this.configUpdate.asObservable();
  private overlayOpen = new Subject<any>();
  overlayOpen$ = this.overlayOpen.asObservable();

  onMenuToggle() {
    mixpanel.track(
      `Sidebar Menu ${this.state.staticMenuDesktopInactive} ? 'Opened : 'Closed'`
    );
    if (this.state.staticMenuDesktopInactive) {
      mixpanel.track('Sidebar Menu Opened');
    } else {
      mixpanel.track('Sidebar Menu Closed');
    }

    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;
      if (this.state.overlayMenuActive) {
        this.overlayOpen.next(null);
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive;
      localStorage.setItem(
        'menuState',
        this.state.staticMenuDesktopInactive.toString()
      );
      this.isMenuActive.next(this.state.staticMenuDesktopInactive);
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }
  }

  showProfileSidebar() {
    this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
    if (this.state.profileSidebarVisible) {
      this.overlayOpen.next(null);
    }
  }

  showConfigSidebar() {
    this.state.configSidebarVisible = true;
  }

  isOverlay() {
    return this.config.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 1199;
  }

  isMobile() {
    return !this.isDesktop();
  }

  onChangeTheme(theme: string) {
    this.config.theme = theme;
    this.onConfigUpdate();
  }

  onConfigUpdate() {
    this.configUpdate.next(this.config);
  }
}
