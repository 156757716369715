import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@wilson/wilsonng';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UttAuthGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(): boolean {
    return this.verifyUserAccess();
  }

  canActivateChild(): boolean {
    return this.verifyUserAccess();
  }

  private verifyUserAccess(): boolean {
    const userIsLoggedIn = this.authenticationService.isLoggedIn();

    if (userIsLoggedIn && !this.userService.userHasUttAccess()) {
      this.router.navigate(['/p/forbidden']);
      return false;
    }

    return userIsLoggedIn;
  }
}
