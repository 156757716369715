<div [ngClass]="containerClass" class="layout-wrapper">
  <div class="layout-sidebar" data-automationId="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div>
    <app-topbar></app-topbar>
    <div class="layout-main-container">
      <div class="layout-main mb-3">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
  <div class="layout-mask"></div>
</div>
