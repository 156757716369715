import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule as PDropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { AvatarModule } from 'primeng/avatar';
import { ChartModule } from 'primeng/chart';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CamelToSentencePipeModule } from '@wilson/wilsonng';
import { AppLayoutModule } from './layout/app.layout.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';

import { DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import { SearchStudentsComponent } from './components/search-students/search-students.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SearchStudentsComponent],
  imports: [
    // angular
    CommonModule,

    // primeng
    PDropdownModule,
    MultiSelectModule,
    DialogModule,
    InputTextModule,
    TableModule,
    CheckboxModule,
    InputTextModule,
    ButtonModule,
    AppLayoutModule,
    TagModule,
    AvatarModule,
    ChartModule,
    SplitButtonModule,
    AutoCompleteModule,
    TabViewModule,
    AccordionModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,

    // devexpress
    DxDataGridModule,
    DxCheckBoxModule,

    // wilsonng
    CamelToSentencePipeModule,
  ],
  exports: [
    // primeng
    PDropdownModule,
    MultiSelectModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    InputTextModule,
    ButtonModule,
    TagModule,
    AvatarModule,
    ChartModule,
    SplitButtonModule,
    AutoCompleteModule,
    TabViewModule,
    AccordionModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    CalendarModule,
    TableModule,

    // wilsonng
    CamelToSentencePipeModule,

    // devexpress
    DxDataGridModule,
    DxCheckBoxModule,

    // app components
    SearchStudentsComponent,

    // directives
  ],
})
export class UIModule {}
