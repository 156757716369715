import { Component } from '@angular/core';
import { finalize, switchMap, tap } from 'rxjs';
import { ActiveElement, AnimationEvent, ChartOptions, Plugin } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { SpinnerService } from '@wilson/wilsonng';
import { FunDashboardService } from '../../services/fun-dashboard.service';
import { InitialLoadService } from '../../services/initial-load.service';
import { FunState } from '../../services/fun-state.service';
import { Wilson } from '../../../def/Wilson';
import GetCompletedByActivityResponse = Wilson.GetCompletedByActivityResponse;
import FunAssignmentRegistry = Wilson.FunAssignmentRegistry;
import GetCompletedByStudentResponse = Wilson.GetCompletedByStudentResponse;
import BenchmarkScoresViewModel = Wilson.BenchmarkScoresViewModel;
import ChartDataLabels from 'chartjs-plugin-datalabels';
import mixpanel from 'mixpanel-browser';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  state: FunState;

  registries: FunAssignmentRegistry[];

  chartPlugins: Plugin[] = [ChartDataLabels];
  chartOptions: ChartOptions[] = [];
  chartDefaultOptions: any = {
    animation: {
      animateRotate: false,
      animateScale: true,
      onComplete: (e: AnimationEvent) => {
        if (!e.initial) return;

        const data = e.chart.data as GetCompletedByStudentResponse;
        const index = this.getDefaultAreaSelection(data);

        e.chart.setActiveElements([{ datasetIndex: 0, index: index }]);
        e.chart.notifyPlugins('afterEvent', { event: { type: 'mousemove' } });
      },
    },
    aspectRatio: 5 / 3,
    events: ['click'],
    hoverBorderWidth: 0,
    hoverOffset: 15,
    layout: { padding: 30 },
    offset: 5,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: 'black',
        font: { size: 15, family: 'Lexend' },
        formatter: (_, c: Context) => {
          const range = Object.values(
            this.initialLoadService.initialLoad.benchmarkScores
          )[c.dataIndex];
          return `${range.min}% - ${range.max}%`;
        },
        offset: 10,
        opacity: (ctx: Context) => (ctx.active ? 1 : 0),
      },
      legend: { display: false },
      tooltip: { enabled: false },
    },
    responsive: true,
  };
  selectedChartAreas: number[];

  studentReport: GetCompletedByStudentResponse[];
  activityReport: GetCompletedByActivityResponse[];

  benchmarkScores: BenchmarkScoresViewModel;

  constructor(
    private dashboardService: FunDashboardService,
    private initialLoadService: InitialLoadService,
    private spinnerService: SpinnerService
  ) {
    this.benchmarkScores = this.initialLoadService.initialLoad.benchmarkScores;
  }

  getDefaultAreaSelection(report: GetCompletedByStudentResponse): number {
    if (Object.keys(report.studentsAboveBench).length) return 0;
    else if (Object.keys(report.studentsAtRisk).length) return 1;
    else if (Object.keys(report.studentsBelowBench).length) return 2;
    else return 3;
  }

  getReports(state: FunState): void {
    this.state = state;

    if (!Array.isArray(state.week) || state.week.length < 1) return;

    this.spinnerService.show();
    this.dashboardService
      .getCompletedByStudent(state.class.id, state.unit, state.week)
      .pipe(
        tap((report) => {
          this.selectedChartAreas = Array(report.length)
            .fill(0)
            .map((_, i) => this.getDefaultAreaSelection(report[i]));

          this.chartOptions = Array(report.length)
            .fill(0)
            .map((_, i) => {
              return {
                ...this.chartDefaultOptions,
                onClick: (_, active) => this.selectChart(active, i),
              };
            });

          this.studentReport = report;
        }),
        switchMap(() =>
          this.dashboardService.getCompletedByActivity(
            state.class.id,
            state.unit,
            state.week as number[]
          )
        ),
        tap((report) => (this.activityReport = report)),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  selectChart(active: ActiveElement[], id: number) {
    if (active.length) return;
    this.selectedChartAreas[id] = 4;
  }

  selectChartArea(event: any, id: number) {
    const index = event.element.index;
    this.selectedChartAreas[id] = index;
    this.trackDonutClick(index);
  }

  private trackDonutClick(index: number) {
    let eventName: string;

    switch (index) {
      case 0:
        eventName = 'DonutGreen';
        break;
      case 1:
        eventName = 'DonutYellow';
        break;
      case 2:
        eventName = 'DonutRed';
        break;
    }

    if (eventName) mixpanel.track(eventName);
  }
}
