import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { InitialLoadService } from './initial-load.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private initialLoadService: InitialLoadService) {}

  setupDatadogMonitoring(): void {
    //applicationId and clientToken values are only set on prod.
    if (
      !this.initialLoadService.initialLoad.datadogTokens['APPLICATION_ID'] &&
      !this.initialLoadService.initialLoad.datadogTokens['CLIENT_TOKEN']
    ) {
      return;
    }
    const datadogConfig: DatadogConfig = {
      environment:
        this.initialLoadService.initialLoad.datadogTokens[
          'MONITORING_ENVIRONMENT'
        ],
      sampleRate:
        this.initialLoadService.initialLoad.datadogTokens['SAMPLE_RATE'],
      applicationID:
        this.initialLoadService.initialLoad.datadogTokens['APPLICATION_ID'],
      clientToken:
        this.initialLoadService.initialLoad.datadogTokens['CLIENT_TOKEN'],
      site: 'datadoghq.com',
      service: 'Fun360',
      trackInteractions: true,
    };

    datadogRum.init({
      applicationId: datadogConfig.applicationID,
      clientToken: datadogConfig.clientToken,
      site: datadogConfig.site,
      service: datadogConfig.service,
      env: datadogConfig.environment,
      sampleRate: +datadogConfig.sampleRate || 100,
      trackInteractions: datadogConfig.trackInteractions,
    });
  }
}

interface DatadogConfig {
  environment: string;
  sampleRate: string;
  applicationID: string;
  clientToken: string;
  site: string;
  service: string;
  trackInteractions: boolean;
}
