import { trackCumulativeLayoutShift } from './trackCumulativeLayoutShift';
import { trackInteractionToNextPaint } from './trackInteractionToNextPaint';
import { trackLoadingTime } from './trackLoadingTime';
import { trackScrollMetrics } from './trackScrollMetrics';
export function trackCommonViewMetrics(lifeCycle, domMutationObservable, configuration, scheduleViewUpdate, loadingType, viewStart) {
    var commonViewMetrics = {};
    var _a = trackLoadingTime(lifeCycle, domMutationObservable, configuration, loadingType, viewStart, function (newLoadingTime) {
        commonViewMetrics.loadingTime = newLoadingTime;
        scheduleViewUpdate();
    }), stopLoadingTimeTracking = _a.stop, setLoadEvent = _a.setLoadEvent;
    var stopScrollMetricsTracking = trackScrollMetrics(configuration, viewStart, function (newScrollMetrics) {
        commonViewMetrics.scroll = newScrollMetrics;
    }).stop;
    var stopCLSTracking = trackCumulativeLayoutShift(configuration, lifeCycle, function (cumulativeLayoutShift) {
        commonViewMetrics.cumulativeLayoutShift = cumulativeLayoutShift;
        scheduleViewUpdate();
    }).stop;
    var _b = trackInteractionToNextPaint(configuration, viewStart, loadingType, lifeCycle), stopINPTracking = _b.stop, getInteractionToNextPaint = _b.getInteractionToNextPaint;
    return {
        stop: function () {
            stopLoadingTimeTracking();
            stopCLSTracking();
            stopScrollMetricsTracking();
            stopINPTracking();
        },
        setLoadEvent: setLoadEvent,
        getCommonViewMetrics: function () {
            commonViewMetrics.interactionToNextPaint = getInteractionToNextPaint();
            return commonViewMetrics;
        },
    };
}
