import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import * as i2 from '@angular/common';
class RouterNavigationService {
  constructor(router, activatedRoute, location) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.location = location;
    this.previousUrl = undefined;
    this.currentUrl = undefined;
  }
  startTrackingPreviousUrl() {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }
  goToPreviousUrl(defaultUrl = '/login') {
    if (!this.previousUrl || this.previousUrl === '/') {
      this.router.navigate([defaultUrl]);
    } else {
      // this prevents being caught in a navigation cycle
      this.location.back();
    }
  }
  getDeepestFirstChild() {
    let currentRoute = this.activatedRoute;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute;
  }
  static {
    this.ɵfac = function RouterNavigationService_Factory(t) {
      return new (t || RouterNavigationService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.Location));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RouterNavigationService,
      factory: RouterNavigationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RouterNavigationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }, {
    type: i1.ActivatedRoute
  }, {
    type: i2.Location
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RouterNavigationService };
