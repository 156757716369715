import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from '../../def/Wilson';
import RequestResultsByActivityDashboard = Wilson.RequestResultsByActivityDashboard;
import GetCompletedByActivityResponse = Wilson.GetCompletedByActivityResponse;
import GetCompletedByStudentResponse = Wilson.GetCompletedByStudentResponse;
import RequestCompletedByStudent = Wilson.RequestCompletedByStudent;

@Injectable({
  providedIn: 'root',
})
export class FunDashboardService {
  constructor(private apiService: ApiService) {}

  getCompletedByActivity(
    classId: string,
    unit: number,
    weeks: number[]
  ): Observable<GetCompletedByActivityResponse[]> {
    return this.apiService.post<GetCompletedByActivityResponse[]>(
      `dashboard/results-by-activity`,
      {
        classId: classId,
        unit: unit,
        weeks: weeks,
      } as RequestResultsByActivityDashboard
    );
  }

  getCompletedByStudent(
    classId: string,
    unit: number,
    weeks: number[]
  ): Observable<GetCompletedByStudentResponse[]> {
    return this.apiService.post<GetCompletedByStudentResponse[]>(
      `dashboard/completed-by-student`,
      {
        classId: classId,
        unit: unit,
        weeks: weeks,
      } as RequestCompletedByStudent
    );
  }
}
