import { Component } from '@angular/core';
import { LayoutService } from '../app.layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
})
export class AppFooterComponent {
  year: number = new Date().getFullYear();

  constructor(private layoutService: LayoutService) {}
}
