import { Component, ElementRef, OnInit } from '@angular/core';
import { LayoutService } from '../app.layout.service';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent implements OnInit {
  theme: string;
  isMenuVisible: boolean;

  constructor(private layoutService: LayoutService, public el: ElementRef) {
    this.theme = this.layoutService.config.theme;
    this.layoutService.configUpdate$.subscribe(
      (config) => (this.theme = config.theme)
    );
  }
  ngOnInit(): void {
    this.watchIsMenuActive().subscribe();
  }

  watchIsMenuActive(): Observable<boolean> {
    return this.layoutService.isMenuPrinted$.pipe(
      tap((value) => {
        this.isMenuVisible = !value;
      })
    );
  }
}
