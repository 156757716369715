<ng-container>
  <a
    (click)="itemClick($event)"
    *ngIf="!item.routerLink"
    [attr.href]="item.url"
    [attr.target]="item.target"
    [ngClass]="item.class"
    [trackClick]="item.trackingEventName"
    class="layout-menuitem"
    tabindex="0"
    pRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <a
    (click)="itemClick($event)"
    *ngIf="item.routerLink"
    [attr.target]="item.target"
    [fragment]="item.fragment"
    [ngClass]="item.class"
    [preserveFragment]="item.preserveFragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [queryParams]="item.queryParams"
    [replaceUrl]="item.replaceUrl"
    [routerLinkActiveOptions]="
      item.routerLinkActiveOptions || {
        exact: item.routerLink === '/',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored'
      }
    "
    [routerLink]="item.routerLink"
    [skipLocationChange]="item.skipLocationChange"
    [state]="item.state"
    [trackClick]="item.trackingEventName"
    class="layout-menuitem"
    data-automationId="sidebar_menu_item"
    routerLinkActive="active-route"
    tabindex="0"
    pRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <ng-container *ngIf="root; else itemContentNoIcon">
      <div class="layout-menuitem-icon wi-icon wi-{{ item.icon }}"></div>
      <span class="layout-menuitem-text">{{ item.label }}</span>
    </ng-container>
    <ng-template #itemContentNoIcon>
      <span class="layout-menuitem-text">{{ item.label }}</span>
    </ng-template>
  </ng-template>

  <ul
    *ngIf="item.items && item.visible !== false"
    [@children]="submenuAnimation"
  >
    <ng-template [ngForOf]="item.items" let-child let-i="index" ngFor>
      <li
        [class]="child.badgeClass"
        [index]="i"
        [item]="child"
        [parentKey]="key"
        app-menuitem
      ></li>
    </ng-template>
  </ul>
</ng-container>
