<ng-container *ngIf="districtReport">
  <h1 data-automationId="district_reports_header">
    {{ districtReport.name }} District Reports
  </h1>
  <p class="p-text-secondary">
    View aggregate Fundation Level reports for all schools within the
    {{ districtReport.name }} district.
  </p>

  <p-tabView (onChange)="trackLevelChange($event.index)">
    <p-tabPanel
      *ngFor="
        let metrics of districtReport.levelMetrics;
        let index = index;
        trackBy: getTracking
      "
      [header]="metrics.label"
    >
      <div class="grid flex-column xl:flex-row">
        <div class="col xl:col-6">
          <div class="card h-100">
            <p class="p-text-secondary">
              An aggregate of average scores by unit for {{ metrics.label }}
              students across the district.
            </p>
            <p-chart
              [data]="metrics.unitScores"
              [height]="300"
              [options]="districtAverageOptions"
              data-automationId="district_reports_chart_average_score"
              type="bar"
            ></p-chart>
          </div>
        </div>

        <div class="col xl:col-6">
          <div class="card h-100">
            <p class="p-text-secondary">
              Total students above or below benchmark score per Fundations unit.
            </p>
            <p-chart
              [data]="metrics.unitBenchmark"
              [height]="300"
              [options]="benchmarkByUnitOptions"
              data-automationId="district_reports_chart_benchmark_score"
              style="max-height: 30vh"
              type="bar"
            ></p-chart>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-container>
