import { Component, OnInit } from '@angular/core';
import { finalize, iif, map, Observable, switchMap, tap } from 'rxjs';
import { ActiveElement, AnimationEvent, ChartOptions, Plugin } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { SpinnerService } from '@wilson/wilsonng';
import { FunAdminDashboardService } from '../../services/fun-admin-dashboard.service';
import { InitialLoadService } from '../../services/initial-load.service';
import { FunState } from '../../services/fun-state.service';
import { Wilson } from '../../../def/Wilson';
import BenchmarkScoresViewModel = Wilson.BenchmarkScoresViewModel;
import GetActivitiesTotalsResponse = Wilson.GetActivitiesTotalsResponse;
import DoughnutDataset = Wilson.DoughnutDataset;
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  state: FunState;

  chartPlugins: Plugin[] = [ChartDataLabels];
  chartOptions: ChartOptions[] = [];
  chartDefaultOptions: any = {
    animation: {
      animateRotate: false,
      animateScale: true,
      onComplete: (e: AnimationEvent) => {
        if (!e.initial) return;

        const data = e.chart.data as CompletedByResponse;
        const index = this.getDefaultAreaSelection(data);

        e.chart.setActiveElements([{ datasetIndex: 0, index: index }]);
        e.chart.notifyPlugins('afterEvent', { event: { type: 'mousemove' } });
      },
    },
    aspectRatio: 5 / 3,
    events: ['click'],
    hoverBorderWidth: 0,
    hoverOffset: 15,
    layout: { padding: 30 },
    offset: 5,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: 'black',
        font: { size: 15, family: 'Lexend' },
        formatter: (_, c: Context) => {
          const range = Object.values(
            this.initialLoadService.initialLoad.benchmarkScores
          )[c.dataIndex];
          return `${range.min}% - ${range.max}%`;
        },
        offset: 10,
        opacity: (ctx: Context) => (ctx.active ? 1 : 0),
      },
      legend: { display: false },
      tooltip: { enabled: false },
    },
    responsive: true,
  };
  selectedChartAreas: number[];

  report: CompletedByResponse[] = [];
  statsData: GetActivitiesTotalsResponse[];

  benchmarkScores: BenchmarkScoresViewModel;

  constructor(
    private adminDashboardService: FunAdminDashboardService,
    private initialLoadService: InitialLoadService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.benchmarkScores = this.initialLoadService.initialLoad.benchmarkScores;
  }

  getReports(): void {
    this.report = undefined;

    if (this.state.isEmpty) {
      return;
    }

    this.spinnerService.show();

    this.getStatsData()
      .pipe(
        switchMap(() =>
          this.state.extras['all']
            ? this.getCompletedByClass()
            : this.getCompletedByStudent()
        ),
        tap((report) => {
          this.selectedChartAreas = Array(report.length)
            .fill(0)
            .map((_, i) => this.getDefaultAreaSelection(report[i]));

          this.chartOptions = Array(report.length)
            .fill(0)
            .map((_, i) => {
              return {
                ...this.chartDefaultOptions,
                onClick: (_, active) => this.selectChart(active, i),
              };
            });

          this.report = report;
        }),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  getCompletedByStudent(): Observable<CompletedByResponse[]> {
    return this.adminDashboardService
      .getCompletedByStudent(
        this.state.class.id,
        this.state.unit,
        this.state.week as number[]
      )
      .pipe(
        map((report) =>
          report.map((item) => ({
            title: item.title,
            labels: item.labels,
            aboveBench: item.studentsAboveBench,
            atRisk: item.studentsAtRisk,
            belowBench: item.studentsBelowBench,
            datasets: item.datasets,
            isStudent: true,
          }))
        )
      );
  }

  getCompletedByClass(): Observable<CompletedByResponse[]> {
    return this.adminDashboardService
      .getCompletedByClass(
        this.state.unit,
        this.state.class.level,
        this.state.class.startingYear,
        this.state.school.id
      )
      .pipe(
        map((report) =>
          report.map((item) => ({
            title: item.title,
            labels: item.labels,
            aboveBench: item.classesAboveBench,
            atRisk: item.classesAtRisk,
            belowBench: item.classesBelowBench,
            datasets: item.datasets,
            isStudent: false,
          }))
        )
      );
  }

  /**
   * Selects the area of the chart that has the highest scores
   * @param report The report data for the doughnut chart
   * @returns The number that corresponds to the section of the chart to be selected
   */
  getDefaultAreaSelection(report: CompletedByResponse): number {
    if (Object.keys(report.aboveBench).length) return 0;
    else if (Object.keys(report.atRisk).length) return 1;
    else if (Object.keys(report.belowBench).length) return 2;
    else return 3;
  }

  getStatsData(): Observable<GetActivitiesTotalsResponse[]> {
    return iif(
      () => this.state.extras['all'],
      this.adminDashboardService.getActivitiesTotalsForSchool(
        this.state.unit,
        this.state.class.level,
        this.state.class.startingYear,
        this.state.school.id
      ),
      this.adminDashboardService.getActivitiesTotalsForClass(
        this.state.unit,
        this.state.class.level,
        this.state.class.id
      )
    ).pipe(tap((response) => (this.statsData = response)));
  }

  selectChart(active: ActiveElement[], id: number) {
    if (active.length) return;
    this.selectedChartAreas[id] = 4;
  }

  selectChartArea(event: any, id: number) {
    this.selectedChartAreas[id] = event.element.index;
  }
}

export interface CompletedByResponse {
  title: string;
  labels: string[];
  aboveBench: { [key: string]: string };
  atRisk: { [key: string]: string };
  belowBench: { [key: string]: string };
  datasets: DoughnutDataset[];
  isStudent: boolean;
}
