import { Component, OnInit } from '@angular/core';
import { RouterNavigationService } from '@wilson/wilsonng';
import { MenuItem } from 'primeng/api';
import { filter, Observable, tap } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  Router,
  TitleStrategy,
} from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { BreadcrumbRootType } from '../../../helpers/breadcrumbRoots';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  path: MenuItem[] = [];

  constructor(
    private router: Router,
    private titleStrategy: TitleStrategy,
    private routerNavigationService: RouterNavigationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.path = this.getBreadcrumbs();
    this.watchRoute().subscribe();
  }

  getRouteUrl = (route: ActivatedRouteSnapshot[]) =>
    route.map((r) => r.url.map((segment) => segment.path).join('/')).join('/');

  getRootTypeUrl(rootType: string): string {
    if (rootType === BreadcrumbRootType.UTT) {
      if ((this.userService.isDistrictAdmin() ||
          this.userService.isSchoolAdmin()) &&
          !this.userService.isTeacher()) {
        return '/utt/school-reports';
      }

      return '/utt/classes';
    }

    if (rootType === BreadcrumbRootType.FHP) {
      return '/';
    }

    return null;
  }

  getBreadcrumbs = (): MenuItem[] =>
    this.routerNavigationService
      .getDeepestFirstChild()
      .pathFromRoot.filter((r) => r.snapshot.routeConfig?.title)
      .map((r) => ({
        label: this.titleStrategy.getResolvedTitleForRoute(r.snapshot),
        routerLink:
          r.routeConfig.data?.canLoad ?? true
            ? this.getRouteUrl(r.snapshot.pathFromRoot)
            : this.getRootTypeUrl(r.routeConfig.data?.rootType),
      }))
      // Remove bad breadcrumbs
      .filter(
        (item) =>
          !(
            !this.userService.isTeacher() &&
            item.label === 'My UTT Classes & Reports'
          )
      );

  watchRoute = (): Observable<Event> =>
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      tap((_) => (this.path = this.getBreadcrumbs()))
    );
}
