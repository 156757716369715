import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GetDistrictReportsResponse = Wilson.GetDistrictReportsResponse;

@Injectable({
  providedIn: 'root',
})
export class DistrictReportsService {
  constructor(private apiService: ApiService) {}

  getDistrictReports(): Observable<GetDistrictReportsResponse> {
    return this.apiService.get<GetDistrictReportsResponse>(
      `districtReports/GetDistrictReports/`
    );
  }
}
